import React from 'react'
import { graphql } from 'gatsby'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
// import moment from 'moment-timezone'

import Layout from '../components/layout'
import SEO from '../components/seo'
import 'react-big-calendar/lib/css/react-big-calendar.css'

// moment.tz.setDefault('America/Los_Angeles')
const localizer = BigCalendar.momentLocalizer(moment)

const mapToRBCFormat = e =>
  Object.assign({}, e, {
    start: moment(e.checkinDate, 'YYYY-MM-DD HH:mm:ss').toDate(),
    end: moment(e.checkoutDate, 'YYYY-MM-DD HH:mm:ss').toDate(),
    allDay: true
  })

export default ({ data }) => {
  const { nodes } = data.allGoogleSpreadsheetResponsesResponses
  console.log(JSON.stringify(nodes, '', 2))
  return (
    <Layout>
      <SEO title="Dashboard" />
      <div>
        {nodes.map((booking, i) => {
          return (
            <React.Fragment key={booking.id}>
              <h1>{booking.title}</h1>
              <p>
                {booking.checkinDate} || {booking.checkoutDate}
              </p>
              <p>Guests: {booking.totalNumberOfGuests}</p>
              <div dangerouslySetInnerHTML={{ __html: booking.notes }} />
            </React.Fragment>
          )
        })}
        <BigCalendar
          localizer={localizer}
          events={nodes.map(mapToRBCFormat)}
          views={['month']}
          step={60}
          showMultiDayTimes
          defaultDate={moment().toDate()}
          style={{ minHeight: '800px' }}
        />
      </div>
    </Layout>
  )
}

export const BOOKING_CALENDAR_QUERY = graphql`
  query AllBookingsQuery {
    allGoogleSpreadsheetResponsesResponses(
      sort: { fields: checkinDate, order: ASC }
      filter: { checkoutDate: { gte: "2019-03-01T07:00:00.000Z" } }
    ) {
      nodes {
        id
        timestamp
        title
        checkinDate
        checkoutDate
        totalNumberOfGuests
        notes
      }
    }
  }
`
